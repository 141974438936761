import React from 'react';
import ReactDOM from 'react-dom/client';
import {ChakraProvider, extendTheme} from '@chakra-ui/react'
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import OnPremLink from './pages/OnPremLink';
import Account from './pages/Account';
import Home from './pages/Home';
import './index.scss';
import RequireAuth from "./modules/RequireAuth";
import AccountSignUp from "./pages/AccountSignUp";
import AccountSignIn from "./pages/AccountSignIn";
import AccountForgotPassword from "./pages/AccountForgotPassword";
import AccountResetPassword from "./pages/AccountResetPassword";
import ProfileSettings from "./pages/ProfileSettings";
import OrderHistory from "./pages/OrderHistory";
import SignOut from "./pages/SignOut";
import {MantineProvider} from '@mantine/core';
import ScrollToHashElement from "./ScrollToHashElement";
import Support from "./pages/Support";

const activeLabelStyles = {
    transform: "scale(0.85) translateY(-26px)"
}

const theme = extendTheme({
    colors: {
        pgrey: {
            100: "#8B8B8B",
            200: "#8B8B8B",
            300: "#8B8B8B",
            400: "#8B8B8B",
            500: "#8B8B8B",
            600: "#8B8B8B",
            700: "#8B8B8B",
            800: "#8B8B8B",
            900: "#8B8B8B",
        },
        pblue: {
            100: "#002d4b",
            200: "#002d4b",
            300: "#002d4b",
            400: "#002d4b",
            500: "#002d4b",
            600: "#002d4b",
            700: "#002d4b",
            800: "#002d4b",
            900: "#002d4b",
        },
    },
    components: {
        Button: {
            baseStyle: {
                height: "3rem",
                borderRadius: "5px",
            }
        },
        Input: {
            baseStyle: {
                borderRadius: "5px",
                "--chakra-sizes-10": "3rem"
            }
        },
        Form: {
            variants: {
                floating: {
                    container: {
                        _focusWithin: {
                            label: {
                                ...activeLabelStyles
                            }
                        },
                        "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label": {
                            ...activeLabelStyles
                        },
                        label: {
                            top: 0,
                            left: 0,
                            zIndex: 2,
                            position: "absolute",
                            color: "#8B8B8B",
                            fontWeight: "normal",
                            backgroundColor: "#F9F9F9",
                            pointerEvents: "none",
                            mx: 3,
                            px: 1,
                            my: 3,
                            transformOrigin: "left top"
                        }
                    }
                }
            }
        }
    }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <MantineProvider>
            <ChakraProvider theme={theme}>
                <BrowserRouter>
                    <ScrollToHashElement/>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/support" element={<Support/>}/>
                        <Route path="/account/sign-in" element={<AccountSignIn/>}/>
                        <Route path="/account/sign-up" element={<AccountSignUp/>}/>
                        <Route path="/account/forgot-password" element={<AccountForgotPassword/>}/>
                        <Route path="/account/reset-password" element={<AccountResetPassword/>}/>
                        <Route element={<RequireAuth/>}>
                            <Route path="/account" element={<Account/>}/>
                            <Route path="/account/on-prem" element={<OnPremLink/>}/>
                            <Route path="/account/profile-settings" element={<ProfileSettings/>}/>
                            <Route path="/account/order-history" element={<OrderHistory/>}/>
                            <Route path="/account/sign-out" element={<SignOut/>}/>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </ChakraProvider>
        </MantineProvider>
);
