import {App} from "../App";
import {Box, Container, Image, Text} from "@chakra-ui/react";
import SimpleSidebar from "../../modules/SideBar";
import React from "react";
import WhatsAppButtonGreenSmall from "../../assets/WhatsAppButtonGreenSmall.svg";

interface AppProps {
    title?: string,
    children: any,
}

export function AppSignedIn({title, children}: AppProps) {
    return <>
        <App>
            <Box paddingTop={"1%"} paddingLeft={{base: "0", xl: "3vw"}} paddingRight={{base: "0", xl: "3vw" }}>
                <SimpleSidebar></SimpleSidebar>
                <Box>
                    {title && <Text display={"block"} as={"b"} mb={"10px"} fontSize='2xl'>{title}</Text>}
                    {children}
                </Box>
            </Box>
            <a href="https://wa.me/+32478978967" target={"_blank"} style={{
                position: "fixed",
                bottom: "20px",
                right: "20px",
                zIndex: 50
            }}>
                <Image
                    alt="Chat on WhatsApp"
                    src={WhatsAppButtonGreenSmall}
                    width={"50px"}
                />
            </a>
        </App>
    </>
}