import './index.scss';
import styles from "../Home/index.module.scss";
import {AppSignedIn} from "../../layouts/AppSignedIn";
import {Card, CardBody, TableContainer, Table, Thead, Tr, Th, Tbody, Td, SkeletonText} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {UserService} from "../../services/user";
import {SimpleServer} from "../../modules/SimpleServer";
import {CookieUtil} from "../../utils/cookie";
import {EntitlementsCardBanner} from "../../modules/EntitlementsCardBanner";

export default function OrderHistory() {
    const [showSkeleton, setShowSkeleton] = useState(true)
    const [orders, setOrders] = useState([])
    const [user, setUser] = useState({} as any)

    async function getOrders() {
        let response = await UserService.getOrders()
        setOrders(response)
        setShowSkeleton(false)
    }

    useEffect(() => {
        UserService.getMyInfo().then(setUser)
        getOrders().then()
    }, [])

  return (
      <AppSignedIn title={"Order history"}>
          <Card className={"customCard"}>
              <CardBody minH={"50vh"}>
                  <EntitlementsCardBanner company={user?.company}/>

                  <TableContainer mt={6}>
                      <Table size='sm'>
                          <Thead>
                              <Tr>
                                  <Th>Reference</Th>
                                  <Th>Date</Th>
                                  <Th>Description</Th>
                                  <Th>Total</Th>
                                  <Th>Status</Th>
                              </Tr>
                          </Thead>
                          <Tbody>
                              {showSkeleton && Array.from({length: 10}).map((_, i)=> (
                                  <Tr key={i}>
                                      <Td><SkeletonText noOfLines={1}></SkeletonText></Td>
                                      <Td><SkeletonText noOfLines={1}></SkeletonText></Td>
                                      <Td><SkeletonText noOfLines={1}></SkeletonText></Td>
                                      <Td><SkeletonText noOfLines={1}></SkeletonText></Td>
                                      <Td><SkeletonText noOfLines={1}></SkeletonText></Td>
                                  </Tr>
                              ))}
                              {!showSkeleton && orders.map((order, i) => (
                                    <Tr key={order.id}>
                                        <Td fontWeight={500}>{order.id}</Td>
                                        <Td><time dateTime={order.created}>{new Date(order.created).toLocaleDateString()}</time></Td>
                                        <Td>{order.quantity}x {order.product} entitlements</Td>
                                        <Td>{CookieUtil.formatCurrency(order.total, order.currency)}</Td>
                                        <Td textTransform={"capitalize"}>{order.status}</Td>
                                    </Tr>
                              ))}
                              {!showSkeleton && orders.length === 0 && (
                                    <Tr>
                                        <Td colSpan={5} textAlign={"center"}>No orders found</Td>
                                    </Tr>
                              )}
                          </Tbody>
                      </Table>
                  </TableContainer>
              </CardBody>
          </Card>
      </AppSignedIn>
  );
}
