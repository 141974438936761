const endpoint = process.env.NODE_ENV === "development" ? "https://dev-hexagg.lesley.co/api" : "/api"

export class HttpService {
    static errorParser(errors){
        for(const key in errors){
            errors[key] = errors[key]?.message || errors[key]
        }

        return errors
    }

    static async post(url: string, json){
        return this._fetch("POST", url, json)
    }

    static async put(url: string, json){
        return this._fetch("PUT", url, json)
    }

    static async get(url: string){
        return this._fetch("GET", url)
    }

    static async _fetch(method: string, url: string, json?: Object){
        let response = await fetch(endpoint + url, {
            method,
            headers: {
                "Content-Type": "application/json",
            },
            body: json ? JSON.stringify(json) : null
        }).then(r => r.json())

        if(response.errors){
            console.error(method, url, response.errors)
            throw this.errorParser(response.errors)
        }

        return response
    }
}