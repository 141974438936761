import styles from "./index.module.scss";
import React, {useEffect, useState} from "react";
import {chakraComponents, Select, useChakraSelectProps} from "chakra-react-select";
import {Image} from "@chakra-ui/react";
import rocky8 from "../../assets/rocky8.png";
import rhel8 from "../../assets/rhel8.png";
import unknownOs from "../../assets/unknown_os.png";
import centos from "../../assets/centos.png";

export function getIconByOS(osString, exact = false){
    return getObjByOS(osString, exact).icon
}

export function getLabelByOS(osString, exact = false){
    return getObjByOS(osString, exact).label
}

export function getObjByOS(osString, exact = false){
    for(let os of supportedTargetOS){
        if(os.value === osString || os.label === osString){
            return os
        }

        for(let regex of os.regex){
            if(regex.test(osString)){
                return os
            }
        }
    }

    return getObjByOS("placeholder")
}

const supportedTargetOS = [
    {
        selectable: false,
        value: "placeholder",
        label: "Reading...",
        icon: unknownOs,
        regex: []
    },
    {
        selectable: true,
        value: "rhel8",
        label: "Red Hat 8",
        icon: rhel8,
        regex: [/^Red Hat/]
    },
    {
        selectable: true,
        value: "rhel8.6",
        label: "Red Hat 8.6",
        icon: rhel8,
        regex: []
    },
    {
        selectable: false,
        value: "centos",
        label: "centos",
        icon: centos,
        regex: [
            /^CentOS/,
        ]
    },
    {
        selectable: true,
        value: "rocky8",
        label: "Rocky 8",
        icon: rocky8,
        regex: [
            /^Rocky/,
        ]
    },
    {
        selectable: true,
        value: "rocky8.6",
        label: "Rocky 8.6",
        icon: rocky8,
        regex: []
    },
]

const customComponents = {
    Option: ({children, ...props}) => (
        // @ts-ignore
        <chakraComponents.Option {...props}>
            <Image src={props.data.icon} mr={2} h={5} w={5} display={"inline"} verticalAlign={"sub"}/> {children}
        </chakraComponents.Option>
    ),
    SingleValue: ({children, ...props}) => (
        // @ts-ignore
        <chakraComponents.SingleValue {...props}>
            <Image src={props.data.icon} mr={2} h={5} w={5} display={"inline"} verticalAlign={"sub"}/> {children}
        </chakraComponents.SingleValue>
    ),
}

export function TargetOsSelector({targetOSValue, setTargetOSValue = (os) => {}, isReadOnly = false}) {
    let targetElement = supportedTargetOS.filter(e => e.value === targetOSValue)[0]
    const [targetOS, setTargetOS] = useState(targetElement)

    useEffect(() => {
        if (targetOS?.value) {
            setTargetOSValue(targetOS.value)
        }
    }, [targetOS])

    const selectProps = useChakraSelectProps({
        placeholder: "Choose target OS",
        onChange: setTargetOS,
        options: supportedTargetOS.filter(s => s.selectable) as any,
        value: targetOS,
    })

    return <Select
        {...selectProps}
        components={customComponents}
        className={`${isReadOnly ? styles.selectReadOnly : ""} ${styles.select}`}
        isReadOnly={isReadOnly}
    />
}