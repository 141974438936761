import React, { ReactNode } from 'react';
import {
    IconButton,
    Box,
    CloseButton,
    Flex,
    Icon,
    useColorModeValue,
    Drawer,
    DrawerContent,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
} from '@chakra-ui/react';
import {
    FiHome,
    FiShoppingBag,
    FiMenu, FiLogOut,
} from 'react-icons/fi';
import { IconType } from 'react-icons';
import styles from "./index.module.scss";
import {Link, useLocation} from "react-router-dom";
import {BsCreditCard, BsHandbag} from "react-icons/bs";
import {CgProfile} from "react-icons/cg";
import {ExternalLinkIcon} from "@chakra-ui/icons";

interface LinkItemProps {
    name: string;
    to: string;
    target: string;
    icon: IconType | any;
}
const LinkItems: Array<LinkItemProps> = [
    { name: 'Dashboard', to: '/account', target: '', icon: FiHome },
    //{ name: 'OnPrem', to: '/account/on-prem', target: '_blank', icon: ExternalLinkIcon },
    { name: 'Profile', to: '/account/profile-settings', target: '', icon: CgProfile },
    { name: 'Orders', to: '/account/order-history', target: '', icon: FiShoppingBag },
    { name: 'Sign out', to: '/account/sign-out', target: '', icon: FiLogOut },
];

export default function SimpleSidebar({ children }: { children?: ReactNode }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <Box minH="100vh" w={{ base: '0', lg: 60 }} float={"left"}>
            <SidebarContent
                onClose={() => onClose}
                display={{ base: 'none', lg: 'block' }}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent onClose={onClose} />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav display={{ base: 'flex', lg: 'none' }} onOpen={onOpen} />
        </Box>
    );
}

interface SidebarProps extends BoxProps {
    onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
    return (
        <Box
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            {LinkItems.map((link) => (
                <NavItem key={link.name} to={link.to} target={link.target} icon={link.icon}>
                    {link.name}
                </NavItem>
            ))}
        </Box>
    );
};

interface NavItemProps extends FlexProps {
    icon: IconType;
    to: string;
    target: string;
    children: any;
}
const NavItem = ({ icon, to, target, children, ...rest }: NavItemProps) => {
    const location = useLocation();
    const isActive = to === location.pathname

    return (
        <Link to={to} target={target} style={{ textDecoration: 'none' }}>
            <Flex
                className={`${styles.navItem} ${isActive ? styles.active : ''}`}
                align="center"
                py="4"
                cursor="pointer"
                {...rest}
            >
                {icon && (
                    <div><Icon
                        mr="4"
                        fontSize="1.25rem"
                        as={icon}
                    /></div>
                )}
                {children}
            </Flex>
        </Link>
    );
};

interface MobileProps extends FlexProps {
    onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
    return (
        <Flex
            ml={{base: 0, md: 60}}
            px={{base: 4, md: 24}}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent="flex-start"
            {...rest}>
            <IconButton
                position={"fixed"}
                left={"20px"}
                top={"30px"}
                zIndex={101}
                variant="outline"
                onClick={onOpen}
                aria-label="open menu"
                icon={<FiMenu/>}
            />
        </Flex>
    );
}