import styles from './index.module.scss';
import {IoMdCheckmark} from "react-icons/io";
import {Box, Button, HStack, Image, Table, Tbody, Td, Tfoot, Th, Thead, Tr, useDisclosure} from "@chakra-ui/react";
import p78Cloud from '../../assets/p78_cloud_white.svg'
import p78OnPrem from '../../assets/p78_on_prem_white.svg'
import {ProductType, PurchaseModal} from "../../modules/PurchaseModal";
import React from "react";

const features = [
    {
        name: 'Seamlessly migrate from RHEL 7/CentOS 7 to RHEL 8/Rocky 8',
        cloud: <IoMdCheckmark/>,
        onPrem: <IoMdCheckmark/>
    },
    {
        name: 'Available to upgrade a single server',
        cloud: <IoMdCheckmark/>,
        onPrem: null,
    },
    {
        name: 'Available from 100 servers',
        cloud: null,
        onPrem: <IoMdCheckmark/>
    },
    {
        name: 'One command upgrading to version 8',
        cloud: <IoMdCheckmark/>,
        onPrem: <IoMdCheckmark/>
    },
    {
        name: 'Supports most third party installed software',
        cloud: <IoMdCheckmark/>,
        onPrem: <IoMdCheckmark/>
    },
    {
        name: 'Requires each server to be able to connect to the update server',
        cloud: <IoMdCheckmark/>,
        onPrem: null
    },
    {
        name: 'Allows massive parallel upgrades',
        cloud: <b>(1)</b>,
        onPrem: <IoMdCheckmark/>
    },
    {
        name: 'Full customisations possibilities for specific software',
        cloud: null,
        onPrem: <IoMdCheckmark/>
    },
    {
        name: 'Added courseware and upgrade documentation',
        cloud: null,
        onPrem: <IoMdCheckmark/>
    },
    {
        name: 'Average upgrade time',
        cloud: <b>45 minutes</b>,
        onPrem: <b>30 minutes</b>
    },
]

export default function Compare({lowestOnPrem, lowestCloud}: { lowestOnPrem: string, lowestCloud: string }) {
    const cloudDisclosure = useDisclosure()
    const onPremDisclosure = useDisclosure()
    lowestOnPrem = lowestOnPrem || "$ XXXXX"
    lowestCloud = lowestCloud || "$ XXXXX"

    return (
        <>
            <Box textAlign={"left"} ml={{
                base: "0",
                lg: "-5%"
            }}>
                <h3 style={{fontWeight: 600, fontSize: "1.1em"}}>Compare Features</h3>
                <br/>
                <p style={{fontSize: "1.1em"}}>Explore the key differences between Cloud and OnPrem solutions with our comprehensive comparison
                    table.</p>
            </Box>
            <HStack display={"flex"} py={10}>
                <Table className={styles.comparisonTable}>
                    <Thead>
                        <Tr>
                            <Th>Compare features</Th>
                            <Th color={"#3aa3a9"}>Cloud</Th>
                            <Th color={"rgb(228, 58, 68)"}>OnPrem</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {features.map((feature, index) => (
                            <Tr key={index}>
                                <Td>{feature.name}</Td>
                                <Td>{feature.cloud}</Td>
                                <Td>{feature.onPrem}</Td>
                            </Tr>
                        ))}
                        <Tr className={"no-border"}>
                            <Td fontWeight={"normal !important"} maxW={"50ch"}>
                                (1) depends on your internet connection as all needed packages will be securely
                                downloaded per server (3-5GB)
                            </Td>
                            <Td></Td>
                            <Td></Td>
                        </Tr>
                        <Tr className={"no-border"}>
                            <Td></Td>
                            <Td>From {lowestCloud}</Td>
                            <Td>From {lowestOnPrem}</Td>
                        </Tr>
                        <Tr className={"no-border"}>
                            <Td></Td>
                            <Td>
                                <PurchaseModal isOpen={cloudDisclosure.isOpen} onClose={cloudDisclosure.onClose}
                                               product={ProductType.CLOUD}/>
                                <Button
                                    onClick={cloudDisclosure.onOpen}
                                    backgroundColor={"var(--primary-cloud-color)"}
                                    color={"white"}
                                    px={{
                                        base: 4,
                                        md: 16
                                    }}
                                    py={{
                                        base: 4,
                                        md: 6
                                    }}
                                    fontSize={{
                                        base: "0.8rem",
                                        md: "1rem"
                                    }}
                                >Buy Now</Button>
                            </Td>
                            <Td>
                                <PurchaseModal isOpen={onPremDisclosure.isOpen} onClose={onPremDisclosure.onClose}
                                               product={ProductType.ON_PREM}/>
                                <Button
                                    onClick={onPremDisclosure.onOpen}
                                    backgroundColor={"var(--primary-onprem-color)"}
                                    color={"white"}
                                    px={{
                                        base: 4,
                                        md: 16
                                    }}
                                    py={{
                                        base: 4,
                                        md: 6
                                    }}
                                    fontSize={{
                                        base: "0.8rem",
                                        md: "1rem"
                                    }}
                                >Buy Now</Button>
                            </Td>
                        </Tr>
                    </Tbody>
                    <Tfoot>

                    </Tfoot>
                </Table>
            </HStack></>
    );
}
